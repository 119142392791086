import React from "react"
import styled from "styled-components"
import Location from "../../Locations/Location"
import breakpoints from "../../../styles/breakpoints"
import BlockContainer from "../../Core/Containers/BlockContainer"
import ContentContainer from "../../Core/Containers/ContentContainer"


const Heading = styled.h2`
  margin-bottom: 34px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 40px;
  }
`

const Container = styled.div`
  display: grid;
  gap: 24px;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const LocationsBlock = ({heading, locations}) => {
  return (
    <BlockContainer>
      <ContentContainer>
        <Heading>{heading}</Heading>
        <Container>
          {locations.map((location, index) => {
            return <Location location={location} key={index} />
          })}
        </Container>

      </ContentContainer>
    </BlockContainer>
  )
}

export default LocationsBlock
