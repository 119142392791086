import React, { useState, Fragment } from "react";
import styled from "styled-components";
import axios from "axios";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import { H4Style } from "../Core/Styles/typography";
import fonts from "../../styles/fonts";
import ArrowOnClickButton from "../Core/Buttons/ArrowOnClickButton";
import RichText from "../Core/RichText";

const FormContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 10px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 98px;
    padding-right: 98px;
    padding-top: 160px;
    padding-bottom: 0px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    gap: 24px;
  }
`;

const InputContainer = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    width: 50%;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px ${colors.greyMedium} solid;
  padding: 6px 0;
  margin-bottom: 24px;
  margin-top: 6px;
  outline: none;

  ${fonts.openSans};
  color: ${colors.greyDark};
  font-size: 14px;
  line-height: 19px;

  ::placeholder {
    color: ${colors.greyMedium};
    font-size: 14px;
    line-height: 19px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border: 1px ${colors.greyMedium} solid;
  resize: none;
  height: 180px;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 24px;

  outline: none;

  ${fonts.openSans};
  color: ${colors.greyDark};
  font-size: 14px;
  line-height: 19px;
`;

const FormLabel = styled.label`
  ${H4Style};
  width: 100%;
`;

const Submit = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    font-weight: 600;
  }
`;

const Success = styled.div`
  padding-top: 60px;
  padding-bottom: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;

const ContactForm = ({ successMessage }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [submitText, setSubmitText] = useState("Submit");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = "/.netlify/functions/contact";

    const data = {
      name: name,
      email: email,
      message: message,
    };

    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        setName("");
        setEmail("");
        setMessage("");
        setSubmitted(true);
      }
    } catch {
      setSubmitText("An error occurred");
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));

    setSubmitText("Submit");
    setSubmitted(false);
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <InputContainer>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="contact[name]"
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Type your name"
              required
            />
          </InputContainer>
          <InputContainer>
            <FormLabel>Email</FormLabel>
            <Input
              type="text"
              name="contact[email]"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Type your email"
              required
            />
          </InputContainer>
        </InputGroup>
        <FormLabel>Message</FormLabel>
        <Textarea
          type="text"
          name="contact[message]"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder="Write your message"
          required
        ></Textarea>
        <Submit>
          <ArrowOnClickButton type="submit">{submitText}</ArrowOnClickButton>
        </Submit>
      </form>
      {submitted && (
        <Success>
          <RichText content={successMessage} />
        </Success>
      )}
    </FormContainer>
  );
};

export default ContactForm;
