import React from "react";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import ContactForm from "../../Forms/ContactForm";
import BlockContainer from "../../Core/Containers/BlockContainer";
import ContentContainer from "../../Core/Containers/ContentContainer";
import fonts from "../../../styles/fonts";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Row, Col } from "styled-bootstrap-grid";

const Heading = styled.h1`

  @media (min-width: ${breakpoints.md}) {
    margin-top: 100px;
  }
`;

const SubTitle = styled.div`
  ${fonts.sourceSerif};
  font-size: 20px;
  line-height: 28px;
  margin-top: 24px;

  p {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  u {
    text-decoration: underline;
  }

  a {
    color: inherit;
  }

  i {
    font-style: italic;
  }

  b {
    font-weight: 600;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 18px;
    font-size: 24px;
    line-height: 33px;
  }
`;

const HeadingContainer = styled(Row)`
`
const LocationsBlock = ({ heading, text, successMessage }) => {
  return (
    <BlockContainer>
      <ContentContainer>
        <HeadingContainer>
          <Col md={8} lg={6}>
            <Heading>{heading}</Heading>
            <SubTitle>{renderRichText(text)}</SubTitle>
          </Col>
        </HeadingContainer>
        <ContactForm successMessage={successMessage} />
      </ContentContainer>
    </BlockContainer>
  );
};

export default LocationsBlock;
