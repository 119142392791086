import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import ExternalLink from "../Core/ExternalLink";
import Image from "../Core/Image";
import { GatsbyImage } from "gatsby-plugin-image";
import { H6Style } from "../Core/Styles/typography";
import RichText from "../Core/RichText"
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import { ReactComponent as ArrowIcon } from "../../resources/img/svg/arrows/map-arrow.svg"

const LocationContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 67%;
  column-gap: 16px;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 24px;
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100% !important;
    border-radius: 10px;
  }
`;

const City = styled.h3`
  color: ${colors.greyDarker};
  margin-bottom: 4px;
`;

const ContactDetails = styled.div`
  p {
    ${H6Style};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const MapLink = styled(ExternalLink)`
  margin-top: 4px;
  ${fonts.openSans};
  font-size: 12px;
  line-height: 25px;
  font-weight: 600;
  color: ${colors.greyDark};
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  
`;

const Location = ({ location }) => {
  const { city, address, email, image, mapLink, telephone } = location;

  return (
    <LocationContainer>
      <ImageContainer>
        <Image image={image} />
      </ImageContainer>
      <div>
        <City>{city}</City>
        <ContactDetails>
          <RichText content={address} />
          {telephone ? <p>{telephone}</p> : null}
          {email ? (
            <p>
              <a href={`mailto:${email}`}>{email}</a>
            </p>
          ) : null}
        </ContactDetails>
        <MapLink href={mapLink}>
          <ArrowIcon />
          <span>Map</span>
        </MapLink>
      </div>
    </LocationContainer>
  );
};

export default Location;
