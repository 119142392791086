import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDark } from "../../../resources/img/svg/arrows/circle-arrow-dark.svg";
import { ReactComponent as ArrowLight } from "../../../resources/img/svg/arrows/circle-arrow-light.svg";
import { H5Style } from "../Styles/typography";
import colors from "../../../styles/colors";

const StyledButton = styled.button`
  ${H5Style};
  color: ${(props) => (props.light ? colors.white : colors.greyDark)};
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const Icon = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowButton = ({ onClick, light, children, type }) => {
  return (
    <StyledButton light={light ? 1 : 0} onClick={onClick} type={type}>
      <Icon>{light ? <ArrowLight /> : <ArrowDark />}</Icon>
      <span>{children}</span>
    </StyledButton>
  );
};

export default ArrowButton;
