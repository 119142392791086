import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import LocationsBlock from "../components/Contact/LocationsBlock"
import FormBlock from "../components/Contact/FormBlock"

const PageContainer = styled.div``

const ContactPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulContactPage

  let sharingImage = false
  if (page.seo.socialMediaImage) {
    sharingImage = page.seo.socialMediaImage.file.url
  }

  return (
    <Layout>
      <Seo
        title={page.seo.metaTitle}
        description={page.seo.metaDescription}
        fullTitle={false}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <FormBlock heading={page.formHeading} text={page.formSubtitle} successMessage={page.successMessage}/>
        <LocationsBlock heading={page.locationsHeading} locations={page.locationsBlock} />
      </PageContainer>
    </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query ContactQuery {
    contentfulContactPage(slug: { eq: "contact" }) {
      formHeading
      formSubtitle {
        raw
      }
      locationsHeading
      seo {
        ...seoFragment
      }
      slug
      successMessage {
        raw
      }
      locationsBlock {
        city
        address {
          raw
        }
        email
        mapLink
        image {
          gatsbyImageData(aspectRatio: 1, width: 826)
          description
        }
        telephone
      }
    }
  }
`;
